import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text, Box, List } from "../components/Core";

const TermsConditions = () => {
  return (
    <>
      <PageWrapper footerDark>
        <Section>
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center">
              <Col lg="12" xl="12">
                <Title variant="hero" className="text-center">
                  Gsm Coin Terms of Service
                </Title>
                <Text variant="small">
                  Last Updated: March 1, 2021
                  <br/>
                  Gsm Coin is a service by Dhru Cloud Pte. Ltd. Dhru Cloud Pte. Ltd (“Gsm Coin” or “we” or “us” or “our”) that simplifies the acceptance of cryptocurrencies as payment for goods and services. Gsm Coin has developed these Terms of Service (these “Terms”) to describe the terms that govern your use of Gsm Coin’s service, Gsm Coin developer documents and our website located at www.gsmcoin.com (the “Site”) (collectively the “Services”).

                </Text>

                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Agreement to Terms
                  </Title>
                  <Text variant="small">
                    Please refer to our Privacy Policy for information on how we collect, use and disclose information from our customers. You acknowledge and agree that your use of the Services is subject to our Privacy Policy.

                  </Text>
                </Box>


                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Changes to Terms or Services
                  </Title>
                  <Text variant="small">
                    We may modify the Terms at any time, in our sole discretion. If we do so, we’ll let you know either by posting the modified Terms on the Site, by providing you a notice in an manner we deem reasonable including notifications within the product’s dashboard, and through other communications. It’s important that you review the Terms whenever we modify them because if you continue to use the Services after we have modified the Terms, you are indicating to us that you agree to be bound by the modified Terms. If you don’t agree to be bound by the modified Terms, you must stop using the Services. We may change or discontinue all or any part of the Services, at any time and without notice, at our sole discretion.
                    <br/>
                    ARBITRATION NOTICE : IF YOU ARE A USER LOCATED IN THE UNITED STATES OR CANADA YOU AGREE THAT DISPUTES BETWEEN YOU AND Gsm Coin WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION AND YOU ARE WAIVING YOUR RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS ACTION OR REPRESENTATIVE PROCEEDING.

                  </Text>
                </Box>


                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Who May Use the Services
                  </Title>
                  <Text variant="small">
                    Eligibility<br/>
                    You may use the Services if you are 13 years or older and are not barred from using the Services under applicable law.
                    <br/><br/>
                    Registration and Your Information<br/>
                    If you want to use the Services you’ll have to create an account (“Account”) on our website. To open an account, you must provide a valid email address, create a password and access to a two-factor authenticator app.
                    <br/>
                    You agree that you won’t disclose your Account credentials to anyone and you’ll notify us immediately of any unauthorized use of your Account. You’re responsible for all activities that occur under your Account, whether or not you know about them.
                    <br/>
                    You are solely responsible for the retention and security of your twelve word recovery phrase (‘Recovery Phrase”). Your Recovery Phrase is the only way to access the cryptocurrency associated with your account. Anyone that has access to your Recovery Phrase can access your cryptocurrency. If you lose your Recovery Phrase, you will not be able to access your cryptocurrency. You acknowledge that Gsm Coin does not store your Recovery Phrase and you agree to hold Gsm Coin harmless in the event you lose your Recovery Phrase and cannot access your cryptocurrency.
                    <br/>
                    When you create your account with us, you will be asked to provide your email address, which we may use on receipts or materials we send to customers on your behalf (“Support Email”). By providing this email, you agree that we may use that Support Email to direct customers that make payments to you using Gsm Coin to contact you for customer support and this Support Email will be made publicly available. If you change your Support Email, you must provide us with prompt notice of such change to enable us to update the Support Email we use on your behalf. You agree to respond to customers contacting you via the Support Email timely.

                  </Text>

                </Box>



                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Feedback
                  </Title>
                  <Text variant="small">
                    We welcome feedback, comments and suggestions for improvements to the Services (“Feedback”). You can submit Feedback by emailing us at info@gsmcoin.com. You grant to us a non-exclusive, worldwide, perpetual, irrevocable, fully-paid, royalty-free, sublicensable and transferable license under any and all intellectual property rights that you own or control to use, copy, modify, create derivative works based upon and otherwise exploit the Feedback for any purpose.

                  </Text>
                </Box>


                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Content and Content Rights
                  </Title>
                  <Text variant="small">
                    For purposes of these Terms: (i) “Content” means text, graphics, images, music, software, audio, video, works of authorship of any kind, and information or other materials that are posted, generated, provided or otherwise made available through the Services; and (ii) “User Content” means any Content that Account holders (including you) provide to be made available through the Services. Content includes without limitation User Content.

                  </Text>
                </Box>


                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Content Ownership, Responsibility and Removal
                  </Title>
                  <Text variant="small">
                    We do not claim any ownership rights in any User Content and nothing in these Terms will be deemed to restrict any rights that you may have to use and exploit your User Content. You agree that you own or have all rights, title and interest, including all intellectual property rights, in any User Content you provide to us.
                    <br/>
                    Subject to the foregoing, Gsm Coin and its licensors exclusively own all right, title and interest in and to the Services and Content, including all associated intellectual property rights. You acknowledge that the Services and Content are protected by copyright, trademark, and other laws of the United States and foreign countries. You agree not to remove, alter or obscure any copyright, trademark, service mark or other proprietary rights notices incorporated in or accompanying the Services or Content.

                  </Text>
                </Box>



                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Rights in User Content Granted by You
                  </Title>
                  <Text variant="small">
                    In order to operate and provide our Services, you grant us a worldwide, non-exclusive, royalty-free, sublicensable, and transferable license to use, copy, distribute, create derivative works of, display, and perform the User Content that you upload, submit, store, send, or receive on or through our Services. The rights you grant in this license are for the purpose of operating and providing our Services, including, but not limited to, identifying merchants on the Services, and/or in a list of merchants using the Services, and in receipts and materials we may send to customers on your behalf. Additional information about your privacy and how we use User Content is available in the Privacy Policy.
                    <br/>
                    You can remove your User Content by specifically deleting it. However, in certain instances, some of your User Content may not be completely removed and copies of your User Content may continue to exist on the Services. We are not responsible or liable for the removal or deletion of (or the failure to remove or delete) any of your User Content.

                  </Text>
                </Box>


                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Rights in Content Granted by Gsm Coin
                  </Title>
                  <Text variant="small">
                    Subject to your compliance with these Terms, we grant you a limited, non-exclusive, non-transferable, non-sublicensable license to download, view, copy, display and print the Content solely in connection with your permitted use of the Services.

                  </Text>
                </Box>


                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Acceptable Use and Gsm Coin’s Enforcement Rights
                  </Title>
                  <Text variant="small">

                    You agree not to use the Services in ways that:<br/><br/>

                    Violate, misappropriate, or infringe the rights of Gsm Coin, our users, or others, including privacy, publicity, intellectual property, or other proprietary rights;<br/>
                    Are illegal, obscene, defamatory, threatening, intimidating, harassing, hateful, racially, or ethnically offensive, or instigate or encourage conduct that would be illegal, or otherwise inappropriate, including promoting violent crimes;<br/>
                    Involve publishing falsehoods, misrepresentations, or misleading statements, including impersonating someone; or<br/>
                    Involve sending illegal or impermissible communications such as bulk messaging, auto-messaging, auto-dialing, and the like;<br/>
                    Avoid, bypass, remove, deactivate, impair, descramble or otherwise circumvent any technological measure implemented by us or any of our service providers or any other third party (including another user) to protect the Services or Content;<br/>
                    Disguise your location through IP proxying or other methods;<br/>
                    Interfere with, or attempt to interfere with, the access of any user, host or network, including, without limitation, sending a virus, overloading, flooding, spamming, or mail-bombing the Services;<br/>
                    Violate any applicable law or regulation; or<br/>
                    Encourage or enable any other individual to do any of the foregoing.<br/>
                    You agree to comply with all applicable U.K. and non-U.K. export control and trade sanctions laws (“Export Laws”). Without limiting the foregoing, you may not use or download the Services if 1) you are in, under the control of, or a national or resident of Cuba, Iran, North Korea, Sudan, or Syria or any other country subject to United States embargo, UN sanctions, HM Treasury’s financial sanctions regime, or if you are on the U.S. Treasury Department’s Specially Designated Nationals List or the U.S. Commerce Department’s Denied Persons List, Unverified List, Entity List HM Treasury’s financial sanctions regime; or (2) you intend to supply any Services to Cuba, Iran, North Korea, Sudan or Syria or any other country subject to United States embargo or HM Treasury’s financial sanctions regime (or a national or resident of one of these countries), or to a person on the Specially Designated Nationals List, Denied Persons List, Unverified List, Entity List, or HM Treasury’s financial sanctions regime.<br/>

                  </Text>
                </Box>




                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Termination
                  </Title>
                  <Text variant="small">
                    We may terminate your access to and use of the Services, at our sole discretion, at any time and without notice to you. You may cancel your Account at any time by following the account closure instructions in the dashboard. Upon termination or cancelation of your account, you will cease all use of the Service and Content. Upon any termination, discontinuation or cancellation of Services or your Account, except as specifically set forth herein, the following provisions will survive: Content and Content Rights, Content Ownership, Responsibility and Removal, Termination, Warranty Disclaimers, Indemnity, Limitation of Liability, Dispute Resolution, and General Terms.

                  </Text>
                </Box>


                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Warranty Disclaimers
                  </Title>
                  <Text variant="small">
                    YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT USE OF THE SERVICES (INCLUDING ANY PRIVATE KEY STORAGE SERVICE OFFERED AS PART OF THE SERVICES, WHETHER CLOUD OR HARDWARE-BASED) AND CONTENT IS AT YOUR SOLE RISK AND THAT THE ENTIRE RISK AS TO SATISFACTORY QUALITY, PERFORMANCE, ACCURACY AND EFFORT IS WITH YOU. THE APP IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT ANY REPRESENTATION OR WARRANTY, WHETHER EXPRESS, IMPLIED OR STATUTORY. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, Gsm Coin SPECIFICALLY DISCLAIMS ANY EXPRESS OR IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND/OR NON-INFRINGEMENT. Gsm Coin DOES NOT MAKE ANY REPRESENTATIONS OR WARRANTIES THAT ACCESS TO THE SERVICES OR ANY OF THE MATERIALS CONTAINED THEREIN WILL BE CONTINUOUS, UNINTERRUPTED, TIMELY, OR ERROR-FREE.
                    <br/>
                    USE OF ANY PRIVATE KEY STORAGE SERVICE INCLUDED AS PART OF THE SERVICES IS OFFERED TO YOU AS A CONVENIENCE, SUBJECT TO THE LIMITATIONS ABOVE. TO BE SAFE, YOU SHOULD ALWAYS BACKUP YOUR PRIVATE ACCESS KEY VIA SECONDARY MEANS.

                  </Text>
                </Box>




                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Indemnity
                  </Title>
                  <Text variant="small">
                    You will indemnify and hold harmless Gsm Coin and its officers, directors, employee and agents, from and against any claims, disputes, demands, liabilities, damages, losses, and costs and expenses, including, without limitation, reasonable legal and accounting fees arising out of or in any way connected with (i) your access to or use of the Services or Content, (ii) your User Content, (iii) Third Party Materials, or (iv) your violation of these Terms.

                  </Text>
                </Box>




                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Limitation of Liability
                  </Title>
                  <Text variant="small">
                    TO THE EXTENT NOT PROHIBITED BY LAW, Gsm Coin SHALL NOT BE LIABLE FOR DAMAGES OF ANY TYPE, WHETHER DIRECT OR INDIRECT, ARISING OUT OF OR IN ANY WAY RELATED TO YOUR USE OR INABILITY TO USE THE SERVICES, INCLUDING BUT NOT LIMITED TO DAMAGES ALLEGEDLY ARISING FROM THE COMPROMISE OR LOSS OF YOUR LOGIN CREDENTIALS OR FUNDS, OR LOSS OF OR INABILITY TO RESTORE ACCESS FROM YOUR BACKUP PHRASE, OR FOR MISTAKES, OMISSIONS, INTERRUPTIONS, DELAYS, DEFECTS AND/OR ERRORS IN THE TRANSMISSION OF TRANSACTIONS OR MESSAGES TO THE ETHEREUM NETWORK, OR THE FAILURE OF ANY MESSAGE TO SEND OR BE RECEIVED BY THE INTENDED RECIPIENT IN THE INTENDED FORM, OR FOR DIMINUTION OF VALUE OF ETHER OR ANY OTHER DIGITAL TOKEN OR DIGITAL ASSET ON THE ETHEREUM NETWORK. Gsm Coin SHALL NOT BE LIABLE UNDER ANY CIRCUMSTANCES FOR ANY LOST PROFITS OR ANY SPECIAL, INCIDENTAL, INDIRECT, INTANGIBLE, OR CONSEQUENTIAL DAMAGES, WHETHER BASED IN CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR OTHERWISE, ARISING OUT OF OR IN CONNECTION WITH AUTHORIZED OR UNAUTHORIZED USE OF THE SERVICES, EVEN IF AN AUTHORIZED REPRESENTATIVE OF Gsm Coin HAS BEEN ADVISED OF OR KNEW OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES. Gsm Coin SHALL NOT BE LIABLE UNDER ANY CIRCUMSTANCES FOR DAMAGES ARISING OUT OF OR IN ANY WAY RELATED TO SOFTWARE, PRODUCTS, SERVICES, AND/OR INFORMATION OFFERED OR PROVIDED BY THIRD-PARTIES AND ACCESSED THROUGH THE APP.
                    <br/>
                    SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OF LIABILITY FOR PERSONAL INJURY, OR OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THIS LIMITATION MAY NOT APPLY TO YOU. IN NO EVENT SHALL Gsm Coin’S TOTAL LIABILITY TO YOU FOR ALL DAMAGES (OTHER THAN AS MAY BE REQUIRED BY APPLICABLE LAW IN CASES INVOLVING PERSONAL INJURY) EXCEED THE AMOUNT OF ONE HUNDRED DOLLARS ($100.00).

                  </Text>
                </Box>




                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Rights in Content Granted by Gsm Coin
                  </Title>
                  <Text variant="small">
                    Subject to your compliance with these Terms, we grant you a limited, non-exclusive, non-transferable, non-sublicensable license to download, view, copy, display and print the Content solely in connection with your permitted use of the Services.

                  </Text>
                </Box>



                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Rights in Content Granted by Gsm Coin
                  </Title>
                  <Text variant="small">
                    Governing Law<br/><br/>
                    These Terms and any action related thereto will be governed by the laws of England and Wales without regard to its conflict of laws provisions.
                    <br/>
                    Forum and Venue<br/><br/>
                    If you are a user located in the United States or Canada, the “Special Arbitration Provision for United States or Canada Users” section below applies to you.
                    <br/>
                    If you are not subject to the “Special Arbitration Provision for United States or Canada Users” section below, you agree that you will resolve any claim you have with us relating to, arising out of, or in any way in connection with our Terms, us, or our Services (each, a “Dispute,” and together, “Disputes”) exclusively in the courts of England and Wales in London and you agree to submit to the personal jurisdiction of such courts for the purpose of litigating all such Disputes.
                    <br/>
                    Special Arbitration Provision for United States or Canada Users<br/>
                    If you are a user located in the United States or Canada, you and Gsm Coin agree that any Dispute shall be finally settled in binding arbitration, on an individual basis, in accordance with the American Arbitration Association’s rules for arbitration of consumer-related disputes (accessible at adr.org/aaa/faces/rules) and you and Gsm Coin hereby expressly waive trial by jury and right to participate in a class action lawsuit or class-wide arbitration, except that each party retains the right: (i) to bring an individual action in small claims court and (ii) to seek injunctive or other equitable relief in a court of competent jurisdiction to prevent the actual or threatened infringement, misappropriation or violation of a party’s copyrights, trademarks, trade secrets, patents or other intellectual property rights (the action described in the foregoing clause (ii), an “IP Protection Action”). The exclusive jurisdiction of an IP Protection Action shall be the courts of England and Wales in London and you agree to submit to the personal jurisdiction of such courts for the purpose of litigating IP Protection Actions.
                    <br/>
                    The arbitration will be conducted by a single, neutral arbitrator and shall take place in the county or parish in which you reside, or another mutually agreeable location, in the English language. The arbitrator may award any relief that a court of competent jurisdiction could award, including attorneys’ fees when authorized by law, and the arbitral decision may be enforced in any court. At your request, hearings may be conducted in person or by telephone and the arbitrator may provide for submitting and determining motions on briefs, without oral hearings. The prevailing party in any action or proceeding to enforce this agreement shall be entitled to costs and attorneys’ fees.
                    <br/>
                    If the arbitrator(s) or arbitration administrator would impose filing fees or other administrative costs on you, we will reimburse you, upon request, to the extent such fees or costs would exceed those that you would otherwise have to pay if you were proceeding instead in a court. We will also pay additional fees or costs if required to do so by the arbitration administrator’s rules or applicable law. Apart from the foregoing, each Party will be responsible for any other fees or costs, such as attorney fees that the Party may incur. If a court decides that any provision of this Special Arbitration Provision is invalid or unenforceable, that provision shall be severed and the other parts of this Special Arbitration Provision shall still apply. In any case, the remainder of this Terms of Service, will continue to apply.

                  </Text>
                </Box>



                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    General Terms
                  </Title>
                  <Text variant="small">
                    These Terms constitute the entire and exclusive understanding and agreement between Gsm Coin and you regarding the Services and Content, and these Terms supersede and replace any and all prior oral or written understandings or agreements between Gsm Coin and you regarding the Services and Content. If any provision of these Terms is held invalid or unenforceable (either by an arbitrator appointed pursuant to the terms of the “Special Arbitration Provision” section above or by a court of competent jurisdiction, that provision will be enforced to the maximum extent permissible and the other provisions of these Terms will remain in full force and effect. You may not assign or transfer these Terms, by operation of law or otherwise, without our prior written consent. Any attempt by you to assign or transfer these Terms, without such consent, will be null. We may freely assign or transfer these Terms without restriction. Subject to the foregoing, these Terms will bind and inure to the benefit of the parties, their successors and permitted assigns.
                    <br/>
                    Any notices or other communications provided by us under these Terms, including those regarding modifications to these Terms, will be given by posting to the Services and/or through other electronic communication.
                    <br/>
                    Our failure to enforce any right or provision of these Terms will not be considered a waiver of such right or provision. The waiver of any such right or provision will be effective only if in writing and signed by a duly authorized representative of Gsm Coin. Except as expressly set forth in these Terms, the exercise by either party of any of its remedies under these Terms will be without prejudice to its other remedies under these Terms or otherwise.

                  </Text>
                </Box>


                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Contact Information
                  </Title>
                  <Text variant="small">
                    If you have any questions about these Terms or the Services, please contact us at info@gsmcoin.com.

                  </Text>
                </Box>





              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export default TermsConditions;
